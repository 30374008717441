import { type ReactNode } from "react";
import { createRouter, RouterContextProvider } from "@tanstack/react-router";
import { routeTree } from "../routeTree";

const router = createRouter({
    basepath: "/",
    caseSensitive: true,
    routeTree,
})

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}

export function withRouter(component: () => ReactNode) {
    return () => <RouterContextProvider router={router}>
        {component()}
    </RouterContextProvider>
}
