import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import NiceModal from "@ebay/nice-modal-react";
import NotFoundPage from "@/pages/404";
import { JobsPanel } from "@/components/common/JobsPanel";
import { RequestEntityProvider } from "@/contexts/useRequestEntity";

export function Page() {
    return <RequestEntityProvider>
        <NiceModal.Provider>
            <Helmet title="Eris" />
            <Outlet />
            {location.pathname !== "/" && <JobsPanel />}
        </NiceModal.Provider>
    </RequestEntityProvider>
}

export const rootRoute = createRootRoute({
    component: Page,
    notFoundComponent: NotFoundPage,
})
