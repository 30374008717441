import { type ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { ExpandedState, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import cn from "classnames";
import BSTable from "react-bootstrap/Table";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import merge from "lodash/merge";
import Spinner from "react-bootstrap/Spinner";
import { DraggableTableHeader } from "./DraggableTableHeader";
import { DragAlongCell } from "./DragAlongCell";
import { DataTable2Props } from "./types";
import { useTableColumns } from "./useTableColumns";
import type { Table, TableOptions } from "@tanstack/react-table";

function DataTableInner<Data extends Record<string, any>>(
    props: DataTable2Props<Data>,
    ref: ForwardedRef<Table<Data>>,
) {
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const expandableProps = useTableColumns(props);
    const [columnOrder, setColumnOrder] = useState<string[]>(() =>
        expandableProps.columns.map((c) => c.id!),
    );
    const mergedTableProps = useMemo<TableOptions<Data>>(
        () =>
            merge(
                {},
                {
                    data: props.data,
                    getCoreRowModel: getCoreRowModel(),
                    state: {
                        expanded,
                        columnOrder,
                    },
                    onExpandedChange: setExpanded,
                    enableSorting: false,
                    enableColumnPinning: false,
                    ...expandableProps,
                } as TableOptions<Data>,
                props.tableOptions ?? {},
            ),
        [columnOrder, expandableProps, expanded, props.data, props.tableOptions],
    );
    const table = useReactTable(mergedTableProps);
    useImperativeHandle(ref, () => table);
    const wrapperClassName = useMemo(
        () => cn("w-100 overflow-scroll position-relative flex-grow-1", props.wrapperClassName),
        [props.wrapperClassName],
    );
    const columnSizeVars = useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes: { [key: string]: number } = {};
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i]!;
            colSizes[`--header-${header.id}-size`] = header.getSize();
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
        }
        return colSizes;
    }, [table.getState().columnSizingInfo]);
    const tableStyle = useMemo(
        () => ({
            ...columnSizeVars,
            width: table.getCenterTotalSize(),
            minWidth: "100%",
        }),
        [columnSizeVars, table],
    );

    // reorder columns after drag & drop
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id as string);
                const newIndex = columnOrder.indexOf(over.id as string);
                return arrayMove(columnOrder, oldIndex, newIndex); // this is just a splice util
            });
        }
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
    );

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
        >
            <div className={wrapperClassName}>
                {props.loading && (
                    <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75 z-3 d-flex align-items-center justify-content-center">
                        <Spinner />
                    </div>
                )}

                <BSTable {...(props.bsTableProps ?? {})} style={tableStyle}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className="position-sticky top-0 z-2 shadow-sm bg-white"
                            >
                                <SortableContext
                                    items={columnOrder}
                                    strategy={horizontalListSortingStrategy}
                                    disabled={!props.canReorder}
                                >
                                    {headerGroup.headers.map((header) => (
                                        <DraggableTableHeader
                                            key={header.id}
                                            header={header}
                                            pinnedColumns={props.pinnedColumns}
                                            canDrag={props.canReorder}
                                        />
                                    ))}
                                    {/* Empty column for proper resizing */}
                                    <th></th>
                                </SortableContext>
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <>
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <SortableContext
                                            key={cell.id}
                                            items={columnOrder}
                                            strategy={horizontalListSortingStrategy}
                                            disabled={!props.canReorder}
                                        >
                                            <DragAlongCell
                                                key={cell.id}
                                                cell={cell}
                                                conditionalCellStyles={props.conditionalCellStyles}
                                            />
                                        </SortableContext>
                                    ))}
                                    {/* Empty column for proper resizing */}
                                    <td></td>
                                </tr>

                                {props.expandableRowsComponent && row.getIsExpanded() && (
                                    <tr>
                                        <td colSpan={1000}>
                                            <props.expandableRowsComponent data={row.original} />
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                    </tbody>
                </BSTable>
            </div>
        </DndContext>
    );
}

export const DataTable2 = forwardRef(DataTableInner) as <Data extends Record<string, any>>(
    props: DataTable2Props<Data> & { ref?: ForwardedRef<Table<Data>> },
) => ReturnType<typeof DataTableInner>;
