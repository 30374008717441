import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { ExportToSpreadsheetMutation, ExportToSpreadsheetMutationVariables } from "@/gql";
import { DEPLOY_EVENT_FRAGMENT } from "../fragments/deploy-event.fragment";

const SCHEMA = gql`
    ${DEPLOY_EVENT_FRAGMENT}

    mutation exportToSpreadsheet(
        $gameId: String!
        $emails: [String!]!
        $templateId: String!
        $templates: [ExportToSpreadsheetTemplate!]!
    ) {
        exportToSpreadsheet(
            emails: $emails
            gameId: $gameId
            templateId: $templateId
            templates: $templates
        ) {
            spreadsheetId
            errors
            warnings {
                ...DeployEvent
            }
        }
    }
`;

export const useExportToSpreadsheet = makeGqlMutationHook<
    ExportToSpreadsheetMutation,
    ExportToSpreadsheetMutationVariables
>(SCHEMA);
