import { CSSProperties } from "react";
import { Column } from "@tanstack/react-table";

export function getCommonPinningStyles<Data extends Record<string, any>>(
    column: Column<Data>,
): CSSProperties {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");

    return {
        backgroundColor: isPinned !== false ? "var(--bs-gray-100)" : undefined,
        boxShadow: isLastLeftPinnedColumn
            ? "-4px 0 4px -4px var(--bs-gray-100) inset"
            : isFirstRightPinnedColumn
              ? "4px 0 4px -4px var(--bs-gray-100) inset"
              : undefined,
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? "sticky" : "relative",
        zIndex: isPinned ? 1 : undefined,
    };
}
