import { columnHelper } from "./helper";
import { DateCell } from "./components/DateCell";
import { UserCell } from "./components/UserCell";
import { JsonCell } from "./components/JsonCell";
import { StatusCell } from "./components/StatusCell";

export const columns = [
    columnHelper.accessor("user", {
        id: "id",
        header: "User",
        size: 350,
        minSize: 350,
        enableSorting: false,
        enableColumnFilter: false,
        cell: UserCell,
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: "Status",
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        cell: StatusCell,
    }),
    columnHelper.accessor("returnValue", {
        id: "returnValue",
        header: "Return value",
        size: 250,
        enableSorting: false,
        enableColumnFilter: false,
        cell: JsonCell,
    }),
    columnHelper.accessor("processedOn", {
        id: "processedOn",
        header: "Processed on",
        size: 180,
        cell: DateCell,
        enableSorting: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor("finishedOn", {
        id: "finishedOn",
        header: "Finished on",
        size: 180,
        cell: DateCell,
        enableSorting: true,
        enableColumnFilter: true,
    }),
];
