import { gql } from "@apollo/client";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { TEMPLATE_PARAM_FRAGMENT } from "@/graphql/fragments/template-param.fragment";
import { TEMPLATE_DISPLAY_FRAGMENT } from "@/graphql/fragments/template-display.fragment";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetTemplateForRendererQuery, GetTemplateForRendererQueryVariables } from "@/gql";

const SCHEMA = gql`
    ${TEMPLATE_FRAGMENT}
    ${TEMPLATE_PARAM_FRAGMENT}
    ${TEMPLATE_DISPLAY_FRAGMENT}

    query getTemplateForRenderer($guid: String!, $gameId: String!) {
        template(guid: $guid, gameId: $gameId) {
            ...Template
            templateParams {
                ...TemplateParam
            }
        }
        templateDisplay(templateId: $guid, gameId: $gameId) {
            ...TemplateDisplay
        }
    }
`;

export const getTemplateForRenderer = makeGqlQueryHook<
    GetTemplateForRendererQuery,
    GetTemplateForRendererQueryVariables
>(SCHEMA);
