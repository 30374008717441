import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Wrapper = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => {
        if (!props.overlay) return "";

        return `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,0.3);
        z-index:6;
        `;
    }}
`;

const StyledSpinner = styled(Spinner)`
    width: 50px;
    height: 50px;
`;

type Props = {
    overlay?: boolean;
};

export function SuspenseFallback(props: Props) {
    return (
        <Wrapper overlay={props.overlay}>
            <StyledSpinner animation="border" />
        </Wrapper>
    );
}
