import { useMemo } from "react";
import stubTrue from "lodash/stubTrue";
import { createColumnHelper, TableOptions } from "@tanstack/react-table";
import { DataTable2Props } from "./types";
import { ExpandRowCell } from "./ExpandRowCell";

const columnHelper = createColumnHelper<any>();

export function useTableColumns<Data extends Record<string, any>>(props: DataTable2Props<Data>) {
    return useMemo<
        Pick<
            TableOptions<Data>,
            "columns" | "enableExpanding" | "manualExpanding" | "getRowCanExpand"
        >
    >(() => {
        const { columns, expandableRowsComponent, getRowCanExpand } = props;
        if (!expandableRowsComponent) return { columns };

        return {
            columns: [
                columnHelper.display({
                    id: "expand",
                    header: "",
                    size: 20,
                    cell: ExpandRowCell,
                }),
                ...props.columns,
            ],
            enableExpanding: true,
            manualExpanding: true,
            getRowCanExpand: getRowCanExpand ?? stubTrue,
        };
    }, [props]);
}
