import { CellContext } from "@tanstack/react-table";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { IconButton } from "@/components/common/IconButton";

export function ExpandRowCell<Data extends Record<string, any>>(props: CellContext<Data, unknown>) {
    const { row } = props;

    return (
        <IconButton
            className="p-1"
            variant="link"
            onClick={row.getToggleExpandedHandler()}
            disabled={!row.getCanExpand()}
            icon={row.getIsExpanded() ? FaChevronDown : FaChevronRight}
        />
    );
}
