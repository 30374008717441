import { gql } from "@apollo/client";
import {
    TemplateEditLockLiftedSubscription,
    TemplateEditLockLiftedSubscriptionVariables,
} from "@/gql";
import { makeGqlSubscriptionHook } from "../util";

const SCHEMA = gql`
    subscription templateEditLockLifted($gameId: String!, $guid: String!) {
        templateEditLockLifted(gameId: $gameId, guid: $guid) {
            guid
            gameId
        }
    }
`;

export const useTemplateEditLockLiftedSubscription = makeGqlSubscriptionHook<
    TemplateEditLockLiftedSubscription,
    TemplateEditLockLiftedSubscriptionVariables
>(SCHEMA);
