import { gql } from "@apollo/client";
import { SetTemplateEditLockSubscription, SetTemplateEditLockSubscriptionVariables } from "@/gql";
import { makeGqlSubscriptionHook } from "../util";

const SCHEMA = gql`
    subscription setTemplateEditLock($gameId: String!, $guid: String!) {
        templateEditLockSet(gameId: $gameId, guid: $guid) {
            guid
            gameId
        }
    }
`;

export const useSetTemplateEditLockSubscription = makeGqlSubscriptionHook<
    SetTemplateEditLockSubscription,
    SetTemplateEditLockSubscriptionVariables
>(SCHEMA);
