import { CellContext } from "@tanstack/react-table";
import { JobModel, Maybe } from "@/gql";
import { DateDisplay } from "../../DateDisplay";

export function DateCell(props: CellContext<JobModel, Maybe<number> | undefined>) {
    const finishedOn = props.getValue();

    return finishedOn ? (
        <span className="small">
            <DateDisplay date={finishedOn} format="DD MMM YYYY HH:mm:ss" />
        </span>
    ) : (
        <span>Never</span>
    );
}
