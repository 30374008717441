import Button, { ButtonProps } from "react-bootstrap/Button";
import styled from "styled-components";
import { ComponentType } from "react";

const StyledButton = styled(Button)<{ $size?: number }>`
    padding: 0.1875rem;
    line-height: 1;
    ${(props) =>
        props.$size &&
        `
        width: ${props.$size}rem;
        min-width: ${props.$size}rem;
        max-width: ${props.$size}rem;
        height: ${props.$size}rem;
        min-height: ${props.$size}rem;
        max-height: ${props.$size}rem;
        line-height: ${props.$size}rem;
        `}
    display: flex;
    align-items: center;
    justify-content: center;
`;

type Props<C> =
    C extends ComponentType<infer P>
        ? ButtonProps & {
              icon: C;
              iconProps?: P;
              width?: number;
          }
        : void;

export function IconButton<C extends ComponentType>(props: Props<C>) {
    const { icon: Icon, width, iconProps, ...buttonProps } = props;
    return (
        <StyledButton {...buttonProps} className={props.className} $size={width}>
            <Icon {...iconProps} />
        </StyledButton>
    );
}
