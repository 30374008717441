import { gql } from "@apollo/client";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { makeGqlQueryHook2 } from "@/graphql/util";
import { TemplateCategoriesQuery, TemplateCategoriesQueryVariables } from "@/gql";

const schema = gql`
    ${TEMPLATE_CATEGORY_FRAGMENT}
    ${TEMPLATE_FRAGMENT}

    query templateCategories($gameId: String!) {
        templateCategories(gameId: $gameId) {
            ...TemplateCategory
            templates {
                ...Template
            }
        }
    }
`;

export const useTemplateCategories = makeGqlQueryHook2<
    TemplateCategoriesQuery,
    TemplateCategoriesQueryVariables
>(schema);
