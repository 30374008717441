import { rootRoute } from "@/pages/__root";
import {
    deployLocksRoute,
    forbiddenRoute,
    gameRoute,
    gamesRoute,
    indexRoute,
    loginRoute,
    pandorasBoxRoute,
    passwordResetRoute,
    profileRoute,
    passwordResetSetNewPasswordRoute,
    wizardryGamesRoute,
    wizardryRoute,
    wizardryUsersRoute,
    templatesRoute,
    entityRoute,
    importRoute,
    categoriesRoute,
    consumersRoute,
    assetsRoute,
    globalParamsRoute,
    blocklyRoute,
    translationsRoute,
    localesRoute,
    glossariesRoute,
    projectHistoryRoute,
    artifactsRoute,
    artifactsListRoute,
    artifactsHistoryRoute,
    artifactsDiffRoute,
    projectRoute,
    projectIndexRoute,
    deployFlagsRoute,
    permissionsRoute,
    deployDirectoriesRoute,
    templateRoute,
    entitiesRoute,
    templateStructureRoute,
    templateParametersRoute,
    templateDisplayRoute,
    exportTemplateToSpreadsheetRoute,
    importTemplateFromSpreadsheetRoute,
    translationRoute
} from "@/routes";

export const routeTree = rootRoute
    .addChildren([
        passwordResetRoute,
        passwordResetSetNewPasswordRoute,
        indexRoute
            .addChildren([
                loginRoute,
                forbiddenRoute,
                gamesRoute,
                profileRoute,
                wizardryRoute
                    .addChildren([
                        wizardryGamesRoute,
                        wizardryUsersRoute,
                        pandorasBoxRoute,
                        deployLocksRoute,
                    ]),
                gameRoute
                    .addChildren([
                        templatesRoute,
                        entityRoute,
                        importRoute,
                        categoriesRoute,
                        consumersRoute,
                        assetsRoute,
                        globalParamsRoute,
                        blocklyRoute,
                        translationRoute,
                        translationsRoute,
                        localesRoute,
                        glossariesRoute,
                        projectHistoryRoute,
                        artifactsRoute.addChildren([
                            artifactsListRoute,
                            artifactsHistoryRoute,
                            artifactsDiffRoute,
                        ]),
                        projectRoute.addChildren([
                            projectIndexRoute,
                            deployFlagsRoute,
                            permissionsRoute,
                            deployDirectoriesRoute,
                        ]),
                        templateRoute.addChildren([
                            entitiesRoute,
                            templateStructureRoute,
                            templateParametersRoute,
                            templateDisplayRoute,
                            exportTemplateToSpreadsheetRoute,
                            importTemplateFromSpreadsheetRoute,
                        ]),
                    ]),

            ]),
    ]);
