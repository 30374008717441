import { useSnackbar } from "notistack";
import {
    CreateTemplateMutation,
    CreateTemplateMutationVariables,
    GetGameQueryVariables,
    OperationResultModel,
    RemoveTemplateMutationVariables,
    RemoveUserPermissionsMutationVariables,
    SetPermissionsDisabledStateMutationVariables,
    SetUserPermissionsMutationVariables,
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables,
} from "@/gql";
import { useGetGameQuery } from "@/graphql/game";
import {
    useCreateTemplateMutation,
    useRemoveTemplateMutation,
    useUpdateTemplateMutation,
} from "@/graphql/templates";
import {
    useRemoveUserPermissionsMutation,
    useSetPermissionsDisabledState,
    useSetUserPermissionsMutation,
} from "@/graphql/permissions";
import { useWrapAction } from "@/store/hooks";
import {
    ADD_USER_PERMISSIONS,
    REMOVE_TEMPLATE,
    REMOVE_USER_PERMISSIONS,
    SET_GAME_DETAILS,
    SET_USER_PERMISSIONS,
    UPDATE_TEMPLATE,
} from "./slice";

export function useLoadGameDetailsAction() {
    const [getGameQuery] = useGetGameQuery();

    return useWrapAction<GetGameQueryVariables>(async (dispatch, variables) => {
        const { data } = await getGameQuery({ variables });
        if (data?.result) {
            dispatch(SET_GAME_DETAILS(data.result));
        }
    });
}

export function useCreateTemplateAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [getGameQuery] = useGetGameQuery();
    const [createTemplateMutation] = useCreateTemplateMutation();

    return useWrapAction<CreateTemplateMutationVariables, CreateTemplateMutation["result"]>(
        async (dispatch, variables) => {
            const { gameId } = variables;
            const { data: result } = await createTemplateMutation({ variables });
            const template = result!.result;
            const { data } = await getGameQuery({ variables: { gameId } });
            dispatch(SET_GAME_DETAILS(data!.result));
            enqueueSnackbar(`Template ${variables.name} created`, {
                variant: "success",
            });
            return template;
        },
    );
}

export function useUpdateTemplateAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [updateTemplateMutation] = useUpdateTemplateMutation();

    return useWrapAction<UpdateTemplateMutationVariables, UpdateTemplateMutation["result"] | null>(
        async (dispatch, variables) => {
            const { data } = await updateTemplateMutation({ variables });
            if (!data?.result) return null;
            // @ts-ignore
            dispatch(UPDATE_TEMPLATE(data.result));
            enqueueSnackbar(`Template ${variables.name} updated`, {
                variant: "success",
            });

            return data!.result;
        },
    );
}

export function useRemoveTemplateAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [removeTemplateMutation] = useRemoveTemplateMutation();

    return useWrapAction<RemoveTemplateMutationVariables>(async (dispatch, variables) => {
        const result = await removeTemplateMutation({ variables });
        if (!result.data) {
            enqueueSnackbar("Template removal failed", {
                variant: "error",
            });
            return;
        }
        dispatch(REMOVE_TEMPLATE({ guid: variables.guid }));
        enqueueSnackbar(`Template ${variables.guid} removed`, {
            variant: "success",
        });
    });
}

export function useAddUserPermissionsAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [setUserPermissionsMutation] = useSetUserPermissionsMutation();

    return useWrapAction<SetUserPermissionsMutationVariables>(async (dispatch, variables) => {
        const { data } = await setUserPermissionsMutation({ variables });
        // dispatch(ADD_USER_PERMISSIONS(data!.result));
        if (data)
            enqueueSnackbar(
                `Added permissions for user ${variables.userId}: ${variables.permissions}`,
                {
                    variant: "success",
                },
            );
    });
}

export function useSetUserPermissionsAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [setUserPermissionsMutation] = useSetUserPermissionsMutation();

    return useWrapAction<SetUserPermissionsMutationVariables>(async (dispatch, variables) => {
        const { data } = await setUserPermissionsMutation({ variables });
        // dispatch(SET_USER_PERMISSIONS(data!.result));
        if (data)
            enqueueSnackbar(
                `Changed permissions for user ${variables.userId}: ${variables.permissions}`,
                {
                    variant: "success",
                },
            );
    });
}

export function useRemoveUserPermissionsAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [removeUserPermissionsMutation] = useRemoveUserPermissionsMutation();

    return useWrapAction<RemoveUserPermissionsMutationVariables, OperationResultModel>(
        async (dispatch, variables) => {
            const { data } = await removeUserPermissionsMutation({ variables });
            if (!data) {
                enqueueSnackbar(`Failed to remove permissions for user ${variables.userId}`, {
                    variant: "error",
                });
                throw new Error();
            }
            dispatch(REMOVE_USER_PERMISSIONS(variables));
            enqueueSnackbar(`Removed permissions for user ${variables.userId}`, {
                variant: "success",
            });
            return data!.result;
        },
    );
}

export function useSetPermissionsDisabledStateAction() {
    const { enqueueSnackbar } = useSnackbar();
    const [setPermissionsDisabledState] = useSetPermissionsDisabledState();

    return useWrapAction<SetPermissionsDisabledStateMutationVariables>(
        async (dispatch, variables) => {
            const { data } = await setPermissionsDisabledState({ variables });
            dispatch(SET_USER_PERMISSIONS(data!.result));
            enqueueSnackbar(
                variables.newDisabledState
                    ? `Disabled permissions for user ${variables.userId}`
                    : `Enabled permissions for user ${variables.userId}`,
                {
                    variant: "success",
                },
            );
        },
    );
}
