import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { AddTemplateToCategoryMutation, AddTemplateToCategoryMutationVariables } from "@/gql";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";

const schema = gql`
    ${TEMPLATE_CATEGORY_FRAGMENT}
    ${TEMPLATE_FRAGMENT}

    mutation addTemplateToCategory($gameId: String!, $templateId: String!, $categoryId: String!) {
        addTemplateToCategory(gameId: $gameId, templateId: $templateId, categoryId: $categoryId) {
            ...TemplateCategory
            templates {
                ...Template
            }
        }
    }
`;

export const useAddTemplateToCategory = makeGqlMutationHook<
    AddTemplateToCategoryMutation,
    AddTemplateToCategoryMutationVariables
>(schema);
