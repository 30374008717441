import { gql } from "@apollo/client";

export const DEPLOY_EVENT_FRAGMENT = gql`
    fragment DeployEvent on DeployEventInterface {
        deployCode
        error
        createdAt
        artifactId
        ... on ConsumerProcessingEvent {
            consumerId
        }
        ... on ConsumerProcessingFailedEvent {
            consumerId
        }
        ... on ConsumerProcessingFinishedEvent {
            consumerId
        }
        ... on EntityUploadEvent {
            entityName
        }
        ... on EntityUploadFailedEvent {
            entityName
        }
        ... on EntityUploadFinishedEvent {
            entityName
        }
        ... on IncorrectValueEvent {
            entityParameterName
            entityId
            entityParameterId
            value
        }
        ... on NotExportableEvent {
            entityId
        }
        ... on RequiredCheckFailedEvent {
            entityId
            entityParameterId
            entityParameterValue
        }
        ... on TemplateValidationEvent {
            templateId
            templateParameterId
        }
        ... on TemplateValidationErrorEvent {
            templateId
            templateParameterId
        }
        ... on TemplateValidationFailedEvent {
            templateId
            templateParameterId
        }
        ... on TemplateValidationMissingReferenceEvent {
            templateId
            templateParameterId
            referencedTemplateId
        }
        ... on UniqCheckFailedEvent {
            entityId
            templateId
            entityParameterId
            entityParameterValue
        }
        ... on CircularReferenceEvent {
            referenceChain
        }
        ... on DeploymentValidationFailedEvent {
            info {
                name
                expected
                received
            }
        }
        ... on MissingReferencedEntityEvent {
            referencingEntityId
            entityParameterId
            entityParameterName
        }
    }
`;
