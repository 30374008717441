import { gql } from "@apollo/client";
import { UpdateTemplateEntitiesMutation, UpdateTemplateEntitiesMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../util";
import { ENTITY_TABLE_DATA_FRAGMENT } from "../fragments/entity-table-data.fragment";

const UPDATE_TEMPLATE_ENTITIES = gql`
    ${ENTITY_TABLE_DATA_FRAGMENT}

    mutation updateTemplateEntities(
        $entityChanges: [EntityChangeDto!]! = []
        $entityParametersChange: [EntityParameterChangeDto!]! = []
        $templateId: String!
        $gameId: String!
    ) {
        result: updateTemplateEntities(
            entityChanges: $entityChanges
            entityParametersChange: $entityParametersChange
            templateId: $templateId
            gameId: $gameId
        ) {
            ...EntityTableData
        }
    }
`;

export const useUpdateTemplateEntitiesMutation = makeGqlMutationHook<
    UpdateTemplateEntitiesMutation,
    UpdateTemplateEntitiesMutationVariables
>(UPDATE_TEMPLATE_ENTITIES);
