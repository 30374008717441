import { FC, type ReactNode } from "react";
import Alert from "react-bootstrap/Alert";
import { OperationVariables } from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { SuspenseFallback } from "@/components/SuspenseFallback";

type DataAwaiterRenderChildrenProps<Data> = {
    children: (data: Data) => ReactNode;
    keepPrev?: boolean;
};

export function DataAwaiter2<Data, Variables extends OperationVariables = Record<string, never>>(
    props: QueryResult<Data, Variables> & DataAwaiterRenderChildrenProps<Data>,
) {
    // Не помню, зачем это изначально было добавлено, но вроде бы для какого-то бага
    // useEffect(() => {
    //     props.refetch();
    // }, []);

    if (props.error) {
        return (
            <Alert className="mb-3" variant="danger">
                <Alert.Heading>Apollo error</Alert.Heading>
                <span>{props.error.message}</span>
            </Alert>
        );
    }

    return (
        <>
            {props.loading && <SuspenseFallback overlay={props.keepPrev} />}
            {props.data
                ? props.children(props.data)
                : props.previousData
                  ? props.children(props.previousData)
                  : null}
        </>
    );
}
