import { type ReactNode } from "react"
import { SnackbarProvider } from "notistack";

export function withSnackbar(component: () => ReactNode) {
    return () => <SnackbarProvider
        autoHideDuration={1500}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
    >
        {component()}
    </SnackbarProvider>
}