import { gql } from "@apollo/client";

export const ENTITY_FRAGMENT = gql`
    fragment EntityFragment on TemplateEntity {
        guid
        wasChanged
        export
        isTestData
        templateId
        gameId
    }
`;
