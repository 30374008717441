import { CellContext } from "@tanstack/react-table";
import { JobModel } from "@/gql";
import { User } from "@/types";
import { UserInfo } from "../../UserInfo";

export function UserCell(props: CellContext<JobModel, User>) {
    const user = props.getValue();
    if (!user) return <span className="small">no user</span>;
    return <UserInfo className="small" user={user} />;
}
